import { useState } from "react";
import { ranks, percentiles, lowerBounds, upperBounds } from "./percentiles";

import "./App.css";

function App() {
  const [mothersAge, setMothersAge] = useState(30);
  const [gestationalAgeW, setGestationalAgeW] = useState(42);
  const [gestationalAgeD, setGestationalAgeD] = useState(0);
  const [birthMonth, setBirthMonth] = useState(1);
  const [nextPeak, setNextPeak] = useState(1);
  const [gestationalWeight, setGestationalWeight] = useState(0);
  const [sibling04, setSibling04] = useState(0);
  const [sibling47, setSibling47] = useState(0);
  const [asthmaHist, setAsthmaHist] = useState(0);
  const [siblingHosp, setSiblingHosp] = useState(0);
  const [motherSmoked, setMotherSmoked] = useState(0);
  const [maleGender, setMaleGender] = useState(0);
  const [HaveTwin, setHaveTwin] = useState(0);
  const [congHeart, setCongHeart] = useState(0);
  const [asdVsd, setAsdVsd] = useState(0);
  const [downSynd, setDownSynd] = useState(0);
  const [esopMal, setEsopMal] = useState(0);
  const [neonatRp, setNeonatRp] = useState(0);

  const gestationalAge =
    parseFloat(gestationalAgeW) * 7 + parseFloat(gestationalAgeD);

  const FRCgestationalAge =
    -0.008526116 * gestationalAge -
    0.0000026026789 * Math.pow(Math.max(gestationalAge - 203, 0), 3) +
    0.0000036156146 * Math.pow(Math.max(gestationalAge - 238, 0), 3) +
    0.000014786345 * Math.pow(Math.max(gestationalAge - 257, 0), 3) -
    0.000056586779 * Math.pow(Math.max(gestationalAge - 276, 0), 3) +
    0.000046504911 * Math.pow(Math.max(gestationalAge - 283, 0), 3) -
    0.0000057174129 * Math.pow(Math.max(gestationalAge - 293, 0), 3);

  let distToNextPeak;

  if (nextPeak - birthMonth >= 0) {
    distToNextPeak = parseFloat(nextPeak) - parseFloat(birthMonth);
  } else {
    distToNextPeak = 12 - parseFloat(birthMonth) + parseFloat(nextPeak);
  }

  const FRCdistance =
    0.15770323 * distToNextPeak -
    0.0086047306 * Math.pow(Math.max(distToNextPeak, 0), 3) +
    0.029222391 * Math.pow(Math.max(distToNextPeak - 4, 0), 3) -
    0.027476176 * Math.pow(Math.max(distToNextPeak - 7, 0), 3) +
    0.0068585149 * Math.pow(Math.max(distToNextPeak - 11, 0), 3);

  const FRCweight =
    -0.11353702 * parseFloat(gestationalWeight) +
    0.022105926 *
      Math.pow(Math.max(parseFloat(gestationalWeight) + 1.7785123, 0), 3) -
    0.060928467 *
      Math.pow(Math.max(parseFloat(gestationalWeight) + 0.54131916, 0), 3) +
    0.040663683 *
      Math.pow(Math.max(parseFloat(gestationalWeight) - 0.22936323, 0), 3) -
    0.0018411422 *
      Math.pow(Math.max(parseFloat(gestationalWeight) - 1.6255334, 0), 3);

  const FRCmotherAge =
    -0.048627475 * mothersAge +
    0.00028458 * Math.pow(Math.max(mothersAge - 21.467488, 0), 3) -
    0.0009113168 * Math.pow(Math.max(mothersAge - 28.172485, 0), 3) +
    0.00073102537 * Math.pow(Math.max(mothersAge - 32.366872, 0), 3) -
    0.00010428857 * Math.pow(Math.max(mothersAge - 39.277207, 0), 3);

  const FRCTwin = 0.26196394 * HaveTwin;
  const FRCSib04 = 0.87154293 * sibling04;
  const FRCSib47 = 0.3217131 * sibling47;
  const FRCSibHospResp = 0.62547002 * siblingHosp;
  const FRCFamAsthma = 0.39925972 * asthmaHist;
  const FRCMotherSmoked = 0.20650808 * motherSmoked;

  const FRCMaleGender = 0.21186408 * maleGender;
  const FRCNeonatResp = 0.33918452 * neonatRp;
  const FRCDown = 0.84563921 * downSynd;
  const FRCEsophagMalf = 1.1382028 * esopMal;
  const FRCcongHD = 1.0538093 * congHeart;
  const FRCUncompASDVSD = 0.34578448 * asdVsd;

  const FRCintercept = -0.45411329;

  const sumFRC =
    FRCgestationalAge +
    FRCdistance +
    FRCweight +
    FRCmotherAge +
    FRCTwin +
    FRCSib04 +
    FRCSib47 +
    FRCSibHospResp +
    FRCFamAsthma +
    FRCMotherSmoked +
    FRCMaleGender +
    FRCNeonatResp +
    FRCDown +
    FRCEsophagMalf +
    FRCUncompASDVSD +
    FRCcongHD +
    FRCintercept;

  const FRCFinalRisk = Math.exp(sumFRC) / (1 + Math.exp(sumFRC));

  // Percentile ranks
  function getRank(value) {
    for (let i = 0; i < percentiles.length; i++) {
      if (value >= lowerBounds[i] && value <= upperBounds[i]) {
        return ranks[i];
      }
    }
    return "out of range";
  }

  const finalRank = getRank(FRCFinalRisk);

  return (
    <div className="App">
      <div className="Dashboard">
        <div className="Headerbox">
          <div className="Logo">
            <h1>RSV Hospitalisation Risk Calculator</h1>
          </div>
          <h4>
            Clinical prediction model for estimating the risk of respiratory
            syncytial virus (RSV) hospitalisation during the first year of life.
          </h4>
          <p className="infoText">
            This calculator is based on a study, available at:
            <a
              href="https://www.thelancet.com/journals/landig/article/PIIS2589-7500(23)00175-9/fulltext"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              {" "}
              https://www.thelancet.com/journals/landig/article/PIIS2589-7500(23)00175-9/fulltext{" "}
            </a>
            <br />
            Complete parts 1-3 to get an estimate. Additional information on a
            variable can be found by hovering the mouse cursor over the
            variable. The predicted risk percentage corresponds to the average
            RSV epidemic, and the actual risk can vary with the RSV epidemic
            intensity.
          </p>
        </div>
        <div className="DashboardWrapper">
          <div className="DashboardHeader">
            <h2>1. Birth information</h2>
          </div>
          <div className="GraphWrapper">
            <form className="Form">
              <div className="FormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    Gestational age at birth
                    <span className="tooltiptext">
                      Duration of pregnancy at birth, in weeks and days.
                    </span>
                  </div>
                </div>
                <div className="FormColumnR">
                  <div className="GestFormRow">
                    <label htmlFor="gestageW" className="GestFormLabel">
                      Weeks
                    </label>
                    <input
                      autoFocus
                      type="number"
                      id="gestageW"
                      inputmode="decimal"
                      name="gestageW"
                      placeholder="42"
                      min="23"
                      max="44"
                      minLength={2}
                      maxLength={2}
                      className="FormInput"
                      onChange={(e) =>
                        setGestationalAgeW(parseInt(e.target.value))
                      }
                      value={gestationalAgeW}
                      style={{
                        backgroundColor:
                          gestationalAgeW > 44 || gestationalAgeW < 23
                            ? "rgba(255, 84, 84, 0.15)"
                            : "",
                      }}
                      required
                    ></input>
                  </div>
                  {(gestationalAgeW > 44 || gestationalAgeW < 23) && (
                    <div className="GestFormRow">
                      <div className="error-text">
                        Input for 'Weeks' has to be between 23 and 44
                      </div>
                    </div>
                  )}

                  <div className="GestFormRow">
                    <label htmlFor="gestageD" className="GestFormLabel">
                      Days
                    </label>
                    <input
                      type="number"
                      inputmode="decimal"
                      id="gestageD"
                      name="gestageD"
                      placeholder="0"
                      min="0"
                      max="6"
                      minLength={1}
                      maxLength={1}
                      className="FormInput"
                      onChange={(e) => setGestationalAgeD(e.target.value)}
                      value={gestationalAgeD}
                      style={{
                        backgroundColor:
                          gestationalAgeD > 6 || gestationalAgeD < 0
                            ? "rgba(255, 84, 84, 0.15)"
                            : "",
                      }}
                      required
                    ></input>
                  </div>
                  {(gestationalAgeD > 6 || gestationalAgeD < 0) && (
                    <div className="GestFormRow">
                      <div className="error-text">
                        Input for 'Days' has to be between 0 and 6
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="FormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="birthmonth" className="FormLabel">
                      Birth month
                    </label>
                  </div>
                </div>
                <div className="FormColumnR">
                  <select
                    name="birthmonth"
                    id="birthmonth"
                    className="FormInput"
                    onChange={(e) => setBirthMonth(e.target.value)}
                    value={birthMonth}
                    required
                  >
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
              </div>
              <div className="FormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="nextpeak" className="FormLabel">
                      Next estimated RSV epidemic peak month{" "}
                      <span className="tooltiptext">
                        The month with the highest estimated RSV epidemic
                        activity in your region. In temperate climates, the peak
                        typically occurs during colder months. <br /> <br />
                        For estimating the peak month, use any method based on
                        best available knowledge. In the development of the
                        model, we used a method of averaging peak months 2
                        years, 4 years and 6 years ago, to account for the
                        biennial variation in the peaks observed in some
                        countries.
                      </span>
                    </label>
                  </div>
                </div>
                <div className="FormColumnR">
                  <select
                    name="nextpeak"
                    id="nextpeak"
                    className="FormInput"
                    onChange={(e) => setNextPeak(e.target.value)}
                    value={nextPeak}
                    required
                  >
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                    <option value="12.000000000000000001">
                      In more than 1 year
                    </option>
                  </select>
                </div>
              </div>

              <div className="FormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="gender" className="FormLabel">
                      Sex{" "}
                    </label>
                  </div>
                </div>
                <div className="FormColumnR">
                  <select
                    name="gender"
                    id="gender"
                    className="FormInput"
                    onChange={(e) => setMaleGender(e.target.value)}
                    value={maleGender}
                  >
                    <option value="1">Male</option>
                    <option value="0">Female</option>
                  </select>
                </div>
              </div>
              <div className="FormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="weight" className="FormLabel">
                      Birth weight in SD{" "}
                      <span className="tooltiptext">
                        Birth weight according to the population-speficic
                        reference values in SD units or Z score. If specific
                        reference values unavailable, use for example: <br />
                        <a
                          href="https://fetalmedicine.org/research/assess/bw"
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "white", wordWrap: "break-word" }}
                        >
                          https://fetalmedicine.org/research/assess/bw
                        </a>
                      </span>
                    </label>
                  </div>
                </div>
                <div className="error-column">
                  <div className="FormColumnR">
                    <input
                      type="number"
                      id="weight"
                      name="weight"
                      inputmode="decimal"
                      min={-4}
                      max={4}
                      placeholder="0"
                      required="required"
                      className="FormInput"
                      onChange={(e) => setGestationalWeight(e.target.value)}
                      value={gestationalWeight}
                      style={{
                        backgroundColor:
                          gestationalWeight > 4 || gestationalWeight < -4
                            ? "rgba(255, 84, 84, 0.15)"
                            : "",
                      }}
                    ></input>
                  </div>
                  {(gestationalWeight > 4 || gestationalWeight < -4) && (
                    <div className="FormColumnR">
                      <div className="error-text">
                        Input for 'Birth weight' has to be between -4 and 4
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="LastFormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="motherage" className="FormLabel">
                      Mother’s age at birth in years{" "}
                    </label>
                  </div>
                </div>
                <div className="error-column">
                  <div className="FormColumnR">
                    <input
                      type="number"
                      inputmode="decimal"
                      id="motherage"
                      name="motherage"
                      placeholder="30"
                      required
                      min="13"
                      max="60"
                      className="FormInput"
                      onChange={(e) => setMothersAge(e.target.value)}
                      value={mothersAge}
                      style={{
                        backgroundColor:
                          mothersAge > 60 || mothersAge < 13
                            ? "rgba(255, 84, 84, 0.15)"
                            : "",
                      }}
                    ></input>
                  </div>
                  {(mothersAge > 60 || mothersAge < 13) && (
                    <div className="FormColumnR">
                      <div className="error-text">
                        Input for 'Mother's age' has to be between 13 and 60
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="DashboardWrapper">
          <div className="DashboardHeader">
            <h2>2. Family members and history</h2>
          </div>
          <div className="GraphWrapper">
            <form className="Form">
              <div className="FormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="HaveTwin" className="FormLabel">
                      Multiple birth{" "}
                      <span className="tooltiptext">
                        Twins, triplets etc born from the same pregnancy.
                      </span>
                    </label>
                  </div>
                </div>
                <div className="FormColumnR">
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="no-twin"
                      name="twin"
                      onChange={(e) => setHaveTwin(parseInt(e.target.value))}
                      value="0"
                      defaultChecked
                    />
                    <label className="radiobutton-label no" htmlFor="no-twin">
                      NO
                    </label>
                    <input
                      type="radio"
                      id="yes-twin"
                      name="twin"
                      onChange={(e) => setHaveTwin(parseInt(e.target.value))}
                      value="1"
                    />
                    <label className="radiobutton-label yes" htmlFor="yes-twin">
                      YES
                    </label>
                  </div>
                </div>
              </div>
              <div className="FormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="siblings04" className="FormLabel">
                      Older siblings aged less than 4 years
                      <span className="tooltiptext">
                        Has older half- or full siblings aged less than 4 years.
                      </span>
                    </label>
                  </div>
                </div>

                <div className="FormColumnR">
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="no-sib-04"
                      name="sib-04"
                      onChange={(e) => setSibling04(parseInt(e.target.value))}
                      value="0"
                      defaultChecked
                    />
                    <label className="radiobutton-label no" htmlFor="no-sib-04">
                      NO
                    </label>
                    <input
                      type="radio"
                      id="yes-sib-04"
                      name="sib-04"
                      onChange={(e) => setSibling04(parseInt(e.target.value))}
                      value="1"
                    />
                    <label
                      className="radiobutton-label yes"
                      htmlFor="yes-sib-04"
                    >
                      YES
                    </label>
                  </div>
                </div>
              </div>

              <div className="FormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="siblings47" className="FormLabel">
                      Older siblings aged 4-7{" "}
                      <span className="tooltiptext">
                        Has half- or full siblings aged 4-7 years.
                      </span>
                    </label>
                  </div>
                </div>

                <div className="FormColumnR">
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="no-sib-47"
                      name="sib-47"
                      onChange={(e) => setSibling47(parseInt(e.target.value))}
                      value="0"
                      defaultChecked
                    />
                    <label className="radiobutton-label no" htmlFor="no-sib-47">
                      NO
                    </label>
                    <input
                      type="radio"
                      id="yes-sib-47"
                      name="sib-47"
                      onChange={(e) => setSibling47(parseInt(e.target.value))}
                      value="1"
                    />
                    <label
                      className="radiobutton-label yes"
                      htmlFor="yes-sib-47"
                    >
                      YES
                    </label>
                  </div>
                </div>
              </div>

              <div className="FormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="famasthma" className="FormLabel">
                      Asthma in any first-degree relative
                      <span className="tooltiptext">
                        Confirmed diagnosis of asthma in mother, father or
                        sibling.
                      </span>
                    </label>
                  </div>
                </div>

                <div className="FormColumnR">
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="no-famasthma"
                      name="famasthma"
                      onChange={(e) => setAsthmaHist(parseInt(e.target.value))}
                      value="0"
                      defaultChecked
                    />
                    <label
                      className="radiobutton-label no"
                      htmlFor="no-famasthma"
                    >
                      NO
                    </label>
                    <input
                      type="radio"
                      id="yes-famasthma"
                      name="famasthma"
                      onChange={(e) => setAsthmaHist(parseInt(e.target.value))}
                      value="1"
                    />
                    <label
                      className="radiobutton-label yes"
                      htmlFor="yes-famasthma"
                    >
                      YES
                    </label>
                  </div>
                </div>
              </div>

              <div className="FormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="sibhospitalizedresp" className="FormLabel">
                      Sibling hospitalized for viral bronchiolitis or wheezing
                      at age 0-4 years{" "}
                    </label>
                  </div>
                </div>

                <div className="FormColumnR">
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="no-sibhospitalizedresp"
                      name="sibhospitalizedresp"
                      onChange={(e) => setSiblingHosp(parseInt(e.target.value))}
                      value="0"
                      defaultChecked
                    />
                    <label
                      className="radiobutton-label no"
                      htmlFor="no-sibhospitalizedresp"
                    >
                      NO
                    </label>
                    <input
                      type="radio"
                      id="yes-sibhospitalizedresp"
                      name="sibhospitalizedresp"
                      onChange={(e) => setSiblingHosp(parseInt(e.target.value))}
                      value="1"
                    />
                    <label
                      className="radiobutton-label yes"
                      htmlFor="yes-sibhospitalizedresp"
                    >
                      YES
                    </label>
                  </div>
                </div>
              </div>

              <div className="LastFormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="mothersmoking" className="FormLabel">
                      Maternal smoking during pregnancy{" "}
                      <span className="tooltiptext">
                        Any smoking of the mother during pregnancy.
                      </span>
                    </label>
                  </div>
                </div>

                <div className="FormColumnR">
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="no-mothersmoking"
                      name="mothersmoking"
                      onChange={(e) =>
                        setMotherSmoked(parseInt(e.target.value))
                      }
                      value="0"
                      defaultChecked
                    />
                    <label
                      className="radiobutton-label no"
                      htmlFor="no-mothersmoking"
                    >
                      NO
                    </label>
                    <input
                      type="radio"
                      id="yes-mothersmoking"
                      name="mothersmoking"
                      onChange={(e) =>
                        setMotherSmoked(parseInt(e.target.value))
                      }
                      value="1"
                    />
                    <label
                      className="radiobutton-label yes"
                      htmlFor="yes-mothersmoking"
                    >
                      YES
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="DashboardWrapper">
          <div className="DashboardHeader">
            <h2>3. Neonatal conditions</h2>
          </div>
          <div className="GraphWrapper">
            <form className="Form">
              <div className="FormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="congheart" className="FormLabel">
                      Severe congenital heart defect, likely requiring operation
                      during the first year of life
                    </label>
                  </div>
                </div>

                <div className="FormColumnR">
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="no-congheart"
                      name="congheart"
                      onChange={(e) => setCongHeart(parseInt(e.target.value))}
                      value="0"
                      defaultChecked
                    />
                    <label
                      className="radiobutton-label no"
                      htmlFor="no-congheart"
                    >
                      NO
                    </label>
                    <input
                      type="radio"
                      id="yes-congheart"
                      name="congheart"
                      onChange={(e) => setCongHeart(parseInt(e.target.value))}
                      value="1"
                    />
                    <label
                      className="radiobutton-label yes"
                      htmlFor="yes-congheart"
                    >
                      YES
                    </label>
                  </div>
                </div>
              </div>

              <div className="FormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="asdvsd" className="FormLabel">
                      Lower complexity atrial or ventricular septal defect
                      <span className="tooltiptext">
                        Uncomplicated congenital heart defect (ASD or VSD),
                        without other congenital heart defects (allowing for
                        patent ductus arteriosus and patent foramen ovale if
                        present), and without the need for corrective operations
                        during the first year of life. Isolated patent foramen
                        ovale or patent ductus arteriosus are not counted as
                        lower complexity congenital heart defects.
                      </span>
                    </label>
                  </div>
                </div>

                <div className="FormColumnR">
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="no-asdvsd"
                      name="asdvsd"
                      onChange={(e) => setAsdVsd(parseInt(e.target.value))}
                      value="0"
                      defaultChecked
                    />
                    <label className="radiobutton-label no" htmlFor="no-asdvsd">
                      NO
                    </label>
                    <input
                      type="radio"
                      id="yes-asdvsd"
                      name="asdvsd"
                      onChange={(e) => setAsdVsd(parseInt(e.target.value))}
                      value="1"
                    />
                    <label
                      className="radiobutton-label yes"
                      htmlFor="yes-asdvsd"
                    >
                      YES
                    </label>
                  </div>
                </div>
              </div>

              <div className="FormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="down" className="FormLabel">
                      Down syndrome
                    </label>
                  </div>
                </div>

                <div className="FormColumnR">
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="no-down"
                      name="down"
                      onChange={(e) => setDownSynd(parseInt(e.target.value))}
                      value="0"
                      defaultChecked
                    />
                    <label className="radiobutton-label no" htmlFor="no-down">
                      NO
                    </label>
                    <input
                      type="radio"
                      id="yes-down"
                      name="down"
                      onChange={(e) => setDownSynd(parseInt(e.target.value))}
                      value="1"
                    />
                    <label className="radiobutton-label yes" htmlFor="yes-down">
                      YES
                    </label>
                  </div>
                </div>
              </div>

              <div className="FormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="esophagusatr" className="FormLabel">
                      Esophagus malformations
                      <span className="tooltiptext">
                        Diagnosis of Q39 group.
                      </span>
                    </label>
                  </div>
                </div>

                <div className="FormColumnR">
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="no-esophagusatr"
                      name="esophagusatr"
                      onChange={(e) => setEsopMal(parseInt(e.target.value))}
                      value="0"
                      defaultChecked
                    />
                    <label
                      className="radiobutton-label no"
                      htmlFor="no-esophagusatr"
                    >
                      NO
                    </label>
                    <input
                      type="radio"
                      id="yes-esophagusatr"
                      name="esophagusatr"
                      onChange={(e) => setEsopMal(parseInt(e.target.value))}
                      value="1"
                    />
                    <label
                      className="radiobutton-label yes"
                      htmlFor="yes-esophagusatr"
                    >
                      YES
                    </label>
                  </div>
                </div>
              </div>

              <div className="LastFormRow">
                <div className="FormColumnL">
                  <div className="tooltip">
                    <label htmlFor="nrp" className="FormLabel">
                      Neonatal respiratory problems in term child
                      <span className="tooltiptext">
                        Term (>= 37 weeks of gestation) child having respiratory
                        conditions with ICD-10 diagnosis group P22 or P24.
                      </span>
                    </label>
                  </div>
                </div>

                <div className="FormColumnR">
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="no-nrp"
                      name="nrp"
                      onChange={(e) => setNeonatRp(parseInt(e.target.value))}
                      value="0"
                      defaultChecked
                    />
                    <label className="radiobutton-label no" htmlFor="no-nrp">
                      NO
                    </label>
                    <input
                      type="radio"
                      id="yes-nrp"
                      name="nrp"
                      onChange={(e) => setNeonatRp(parseInt(e.target.value))}
                      value="1"
                    />
                    <label className="radiobutton-label yes" htmlFor="yes-nrp">
                      YES
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="DashboardWrapper">
          <div className="ResultsGraphWrapper">
            <form>
              <div className="results">
                <h3>
                  Predicted RSV hospitalisation risk during an average epidemic:{" "}
                  <br></br> {(FRCFinalRisk * 100).toFixed(2)} %
                </h3>
                <h3>Corresponding to {finalRank} rank percentile </h3>
              </div>
            </form>
          </div>
        </div>
        <div className="disclaimer">
          Disclaimer. This tool is not a medical device, nor does it provide a
          medical or diagnostic service. The tool does not collect any data. The
          results are intended for use by healthcare professionals for
          informational and educational purposes only. The information provided
          is not an attempt to practice medicine or provide specific medical
          advice, and should not be used to make a diagnosis or in the place of
          a qualified healthcare professional's clinical judgment. You assume
          full responsibility for using the information provided by the tool,
          and you understand and agree that the authors of this study, and its
          affiliates and subsidiaries are not responsible or liable for any
          claim, loss, damage or injury, including but not limited to death,
          resulting from the use of this tool by you or any user. We do not
          guarantee the validity, completeness, accuracy or timeliness of
          information provided by the tool or that access to the website will be
          error- or virus-free. We disclaim any warranty, whether express or
          implied, including warranties of merchantability or fitness for a
          particular purpose.
        </div>
      </div>
    </div>
  );
}

export default App;
