export const ranks = [
    "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th",
    "11th", "12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th",
    "21st", "22nd", "23rd", "24th", "25th", "26th", "27th", "28th", "29th", "30th",
    "31st", "32nd", "33rd", "34th", "35th", "36th", "37th", "38th", "39th", "40th",
    "41st", "42nd", "43rd", "44th", "45th", "46th", "47th", "48th", "49th", "50th",
    "51st", "52nd", "53rd", "54th", "55th", "56th", "57th", "58th", "59th", "60th",
    "61st", "62nd", "63rd", "64th", "65th", "66th", "67th", "68th", "69th", "70th",
    "71st", "72nd", "73rd", "74th", "75th", "76th", "77th", "78th", "79th", "80th",
    "81st", "82nd", "83rd", "84th", "85th", "86th", "87th", "88th", "89th", "90th",
    "91st", "92nd", "93rd", "94th", "95th", "96th", "97th", "98th", "99th", "100th"
  ];


export const percentiles = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
    51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
    61, 62, 63, 64, 65, 66, 67, 68, 69, 70,
    71, 72, 73, 74, 75, 76, 77, 78, 79, 80,
    81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
    91, 92, 93, 94, 95, 96, 97, 98, 99, 100
    ];

export const lowerBounds = [
    0, 0.001904096, 
    0.002054278, 
    0.002180187, 
    0.002294615, 
    0.002398027, 
    0.002496932, 
    0.002597319, 
    0.002698661, 
    0.00280015, 
    0.002905221, 
    0.003010537,
    0.003124904, 
    0.003248256, 
    0.00337603, 
    0.003513621, 
    0.003662475, 
    0.003820234, 
    0.003984306, 
    0.00414956, 
    0.004321951, 
    0.004495575, 
    0.004670185, 
    0.004840827, 
    0.005006096, 
    0.005177785, 
    0.005352452, 
    0.005524109, 
    0.005694298, 
    0.005868494, 
    0.006042824, 
    0.006220701, 
    0.006402139, 
    0.006585417, 
    0.00677455, 
    0.006973739, 
    0.007174302, 
    0.007384655, 
    0.007601162, 
    0.007820368, 
    0.008045179, 
    0.008270076, 
    0.008495974, 
    0.00871987, 
    0.008945911, 
    0.009168909, 
    0.009394435, 
    0.009620297, 
    0.00985246, 
    0.01009028, 
    0.010325189, 
    0.010559604, 
    0.010795933, 
    0.011036936, 
    0.011280582, 
    0.011525767, 
    0.011781629, 
    0.012047923, 
    0.012317159, 
    0.012587007, 
    0.012865251, 
    0.013159775, 
    0.013473697, 
    0.013798911, 
    0.014131152, 
    0.014489347, 
    0.014865699, 
    0.015268745, 
    0.015697532, 
    0.016145479, 
    0.01663749, 
    0.01715906, 
    0.017719998, 
    0.018322939, 
    0.018948106, 
    0.019610032, 
    0.020310475, 
    0.021033905, 
    0.0217947, 
    0.022563044, 
    0.023350357, 
    0.024162681, 
    0.024981987, 
    0.02581671, 
    0.026682823, 
    0.02759323, 
    0.028541917, 
    0.029548527, 
    0.030639256, 
    0.031812841, 
    0.03308487, 
    0.034541411, 
    0.036177969, 
    0.038105305, 
    0.040403796, 
    0.043320007, 
    0.047071268, 
    0.05254726, 
    0.061284936, 
    0.077882956
];

export const upperBounds = [
    0.001904096,
    0.002054278,
    0.002180187,
    0.002294615,
    0.002398027,
    0.002496932,
    0.002597319,
    0.002698661,
    0.00280015,
    0.002905221,
    0.003010537,
    0.003124904,
    0.003248256,
    0.00337603,
    0.003513621,
    0.003662475,
    0.003820234,
    0.003984306,
    0.00414956,
    0.004321951,
    0.004495575,
    0.004670185,
    0.004840827,
    0.005006096,
    0.005177785,
    0.005352452,
    0.005524109,
    0.005694298,
    0.005868494,
    0.006042824,
    0.006220701,
    0.006402139,
    0.006585417,
    0.00677455,
    0.006973739,
    0.007174302,
    0.007384655,
    0.007601162,
    0.007820368,
    0.008045179,
    0.008270076,
    0.008495974,
    0.00871987,
    0.008945911,
    0.009168909,
     0.009394435,
    0.009620297,
    0.00985246,
    0.01009028,
    0.010325189,
    0.010559604,
    0.010795933,
    0.011036936,
    0.011280582,
    0.011525767,
    0.011781629,
    0.012047923,
    0.012317159,
    0.012587007,
    0.012865251,
    0.013159775,
    0.013473697,
    0.013798911,
    0.014131152,
    0.014489347,
    0.014865699,
    0.015268745,
    0.015697532,
    0.016145479,
    0.01663749,
    0.01715906,
    0.017719998,
    0.018322939,
    0.018948106,
    0.019610032,
    0.020310475,
    0.021033905,
    0.0217947,
    0.022563044,
    0.023350357,
    0.024162681,
    0.024981987,
    0.02581671,
    0.026682823,
    0.02759323,
    0.028541917,
    0.029548527,
    0.030639256,
    0.031812841,
    0.03308487,
    0.034541411,
    0.036177969,
    0.038105305,
    0.040403796,
    0.043320007,
    0.047071268,
    0.05254726,
    0.061284936,
    0.077882956,
    1
];

